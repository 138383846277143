<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div v-if="posts.length > 0" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title">All Posts</h3>
            <div class="card-toolbar">
              <router-link
                to="/posts/new"
                class="btn btn-mc font-weight-bolder font-size-sm"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  /> </span
                >Add New Post
              </router-link>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive">
              <table
                class="table table-head-custom table-head-bg table-vertical-center"
              >
                <thead>
                  <tr class="text-left text-uppercase">
                    <th class="pl-7" style="width: 50px">Title</th>
                    <th style="min-width: 120px"></th>
                    <th style="min-width: 120px">Category</th>
                    <th style="min-width: 150px" class="text-right">
                      Is publish
                    </th>
                    <th style="min-width: 110px" class="pr-7 text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(post, key) in posts" :key="key">
                    <td class="pl-0" :class="{ 'border-top-0': key === 0 }">
                      <div class="symbol symbol-50 symbol-light mt-1">
                        <img
                          v-if="post.featuredImage.src"
                          :src="
                            appPath +
                              '/media/cache/resolve/50x50/' +
                              post.featuredImage.src
                          "
                          class="h-75 align-self-end"
                          alt=""
                        />
                        <div v-else class="symbol-label"></div>
                      </div>
                    </td>
                    <td class="pl-0" :class="{ 'border-top-0': key === 0 }">
                      <router-link
                        :to="'/posts/edit/' + post.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ post.title }}</router-link
                      >
                      <span
                        class="text-muted font-weight-bold text-muted d-block"
                        ><span>Created: </span
                        ><span>{{
                          post.createdAt | moment('DD.MM.YY')
                        }}</span></span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        class="label label-lg label-light-primary label-inline"
                        >Video nadzor</span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <MCSwitch
                        @checkedEvent="
                          updateIsPublish(post.isPublish, post.id, key)
                        "
                        :is-checked="post.isPublish"
                        classes="switch-sm switch-icon float-right"
                        name="postIsPublish"
                      />
                    </td>
                    <td
                      class="pr-0 text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <router-link
                        :to="'/posts/edit/' + post.id"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Communication/Write.svg"
                          />
                        </span>
                      </router-link>
                      <button
                        @click="removePost(post.id)"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="media/svg/icons/General/Trash.svg" />
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" type="grow"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import MCSwitch from '@/view/content/components/switch/MCSwitch'

export default {
  name: 'ListPosts',
  components: {
    MCSwitch
  },
  data() {
    return {
      posts: [],
      totalItems: null,
      appPath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Posts', route: '/posts/list' }
    ])

    ApiService.get(`/posts`, `?order[createdAt]=desc`).then(({ data }) => {
      this.totalItems = data['hydra:totalItems']
      this.posts = [...data['hydra:member']]
    })
  },
  methods: {
    updateIsPublish(checked, entityId, key) {
      this.posts[key].isPublish = !checked

      ApiService.update('/posts', entityId, {
        isPublish: this.posts[key].isPublish
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Post successful updated.'
        })
      })
    },
    async deletePost(id) {
      try {
        return await ApiService.delete('/posts/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    removePost(id) {
      const confirmation = confirm('You want delete this post?')
      if (confirmation === false) return

      this.deletePost(id).then(({ status }) => {
        if (status === 204) {
          this.posts = this.posts.filter(product => product.id !== id)
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Post success deleted from database'
          })
        }
      })
    }
  }
}
</script>
